import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import ContentSection from "../components/content/section";


export default class Dashboardd extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	componentDidMount() {}

	render() {
		return (
			<Layout>
				<SEO title="Ducat Staking Supernova" />
				<div className="dashboard">
					<ContentSection title="The Supernova">
						<p>The DUCAT Supernova distributes DUCAT tokens to those who provide liquidity on Uniswap V2. The more liquidity you provide, and for longer, the greater share of the DUCAT pool you receive.</p>
						<p>The pool in Supernova unlocks gradually over time, and stakers receive their share over time.</p>
						<p>The total size of Supernova pool is 10% of total supply.</p>
						<h6>User flow:</h6>
						<p><span className="special">1. </span>Deposit ETH and DUCAT into Uniswap V2 Pool</p>
						<p><span className="special">2. </span>Receive UNI-V2 LP Tokens</p>
						<p><span className="special">3. </span>Stake those UNI-V2 LP Tokens in the Supernova</p>
						<p>That's it! Once you've deposited staking tokens, you can check your current stake and reward amounts using the Supernova interface. You can add more staked liquidity whenever you want and there is no minimum lockup period. You receive your share of the Supernova pool when you unstake.</p>
					</ContentSection>
					<ContentSection title="Who holds the funds?">
						<p>Uniswap contracts hold the tokens associated with the UNI-V2 LP tokens as they normally do. The Supernova contracts hold the staked UNI-V2 tokens and the DUCAT tokens for distribution (in the locked and unlocked pools). All ownership is accounted for on-chain via smart contracts.</p>
					</ContentSection>

					<ContentSection title="How is my pool share calculated?">
						<p>The more you stake and the longer you stake them for relative to others, the greater share of the unlock pool you receive.</p>
						<p>Ownership share of the unlock pool is equal to: <br/><span className="code">User_staking_token_time / Global_staking_token_time</span></p>
						<p>Imagine there are two users in the system, Alice and Bob. Alice has staked 10 DUCAT for 1 day, Bob has staked 5 DUCAT for 3 days.</p>
						<div className="code">
							<p>Alice_token_time = 10 DUCAT * 1 days = 10<br/>
							Bob_token_time = 5 DUCAT * 3 days = 15</p>

							<p>Global_staking_token_time = (Alice_token_time) + (Bob_token_time) = 25 token_days</p>

							<p>Alice owns (10 / 25) = 40%<br/>
							Bob owns (15 / 25) = 60%</p>
						</div>
						<p>The ownership percentage is over the unlocked pool. As soon as you withdraw your staked tokens, you get your share of the currently unlocked tokens, irrespective of the locked tokens still waiting to unlock.</p>
						<p>Ownership percentages and token unlocks are continuous, meaning they're calculated block-by-block.</p>
					</ContentSection>
					<ContentSection title="Do rebases still impact the Uniswap DUCAT and Supernova pool DUCAT?">
						<p>Yes. DUCAT supply changes are universal and affect all users proportially and equally, whether they are an end-user (EOA) wallet or a contract wallet.</p>

						<p>This means the Supernova locked pool, Supernova unlocked pool, and Uniswap's DUCAT pool can increase or decrease in size each rebase period. Your UNI-V2 LP tokens remain static in balance, but can be converted into the correct amount of DUCAT when they are redeemed, similar to Compound CTokens.</p>
					</ContentSection>
					<ContentSection title="Do I still receive my Uniswap LP trading fees?">
						<p>Yes. The profit-and-loss of Uniswap tokens are unchanged by the Supernova. The Supernova contracts simply hold the UNI-V2 LP tokens for you as long as they are staked.</p>
					</ContentSection>
					<ContentSection title="Have the smart contracts been audited?">
						<p>Near identical on-chain code of Supernova has been audited by CertiK. Outside of variable changes for duration and amount, the Solidity code has widely remained untouched for Supernova.</p>
					</ContentSection>
					<ContentSection title=" Is there an owner key? Can the smart contracts be changed?">
						<p>The code is non-upgradeable and cannot be paused or halted.</p>
					</ContentSection>
				</div>
			</Layout>
		)
	}
}